//Check if product has calories

//Check if Product is dogfood and right format
function isDogFood(product) {
  const productCal = product.sortedProperties?.find(
    (item) => item.name === "kcal / 100g"
  );
  if (
    product &&
    product?.unit?.shortCode === "kg" &&
    (product.customFields?.productGroup?.identifier === "snacks" ||
      product.customFields?.productGroup?.identifier === "dry_food" ||
      product.customFields?.productGroup?.identifier === "wet_food") &&
    product.extensions?.animalSpecies?.find(
      (item) => item.identifier === "dogs"
    ) &&
    productCal
  ) {
    return true;
  } else {
    return false;
  }
}
function isSnack(product) {
  const productCal = product.sortedProperties?.find(
    (item) => item.name === "kcal / 100g"
  );
  if (
    product &&
    product?.unit?.shortCode === "kg" &&
    product.customFields?.productGroup?.identifier === "snacks" &&
    product.extensions?.animalSpecies?.find(
      (item) => item.identifier === "dogs"
    ) &&
    productCal
  ) {
    return true;
  } else {
    return false;
  }
}

//Check if Calories are calculated

function getPortionPerDay(product, index = 0) {
  const productCal = product.sortedProperties?.find(
    (item) => item.name === "kcal / 100g"
  );
  const persistedData = JSON.parse(window.localStorage.getItem("dailyCal"));
  const dailyCal =
    persistedData && persistedData.length && persistedData[0].calories
      ? persistedData[index]
      : null;
  if (isDogFood(product) && dailyCal?.calories && productCal) {
    let gramsPerCalories = 100 / productCal.options[0].name;
    return Math.round(gramsPerCalories * dailyCal.calories);
  }
  return null;
}

function getPricePerDay(product, index = 0) {
  let dailyPrice =
    (product?.calculatedPrice?.unitPrice / (product.purchaseUnit * 1000)) *
    getPortionPerDay(product, index);

  if (dailyPrice) {
    return dailyPrice.toFixed(2);
  }
  return null;
}

function getlastingDays(product, index = 0) {
  return Math.round(
    (product?.purchaseUnit * 1000) / getPortionPerDay(product, index)
  );
}

function mapCalories(product) {
  const persistedCalories = JSON.parse(window.localStorage.getItem("dailyCal"));
  let dailyCal =
    persistedCalories &&
    persistedCalories.length &&
    persistedCalories[0].calories
      ? persistedCalories
      : null;

  if (product && isDogFood(product) && dailyCal) {
    dailyCal = dailyCal.map((item, index) => ({
      ...item,
      portionPerDay: getPortionPerDay(product, index),
      pricePerDay: getPricePerDay(product, index),
      lastingDays: getlastingDays(product, index),
    }));
  }

  return dailyCal;
}

export {
  isDogFood,
  isSnack,
  getPortionPerDay,
  getPricePerDay,
  getlastingDays,
  mapCalories,
};
